import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { LocalNotifications } from '@capacitor/local-notifications';
import { PushNotifications } from '@capacitor/push-notifications';
import type {
  ActionPerformed,
  PushNotificationSchema,
} from '@capacitor/push-notifications';
// import { SplashScreen } from '@capacitor/splash-screen';
import { Badge } from '@capawesome/capacitor-badge';
import { isPlatform } from '@ionic/vue';

import {
  MessageTypeEnum,
  MessengerChatTypeEnum,
  NotificationsPushActionsEnum,
  PushType,
} from '@/enums';
import {
  htmlToText,
  isNativeMobile,
  showToast,
  useLoading,
  useTaskManagement,
} from '@/helpers';
import { useI18n } from '@/i18n';
import router, { ROUTES_NAME } from '@/router';
import { $api } from '@/services';
import type { AppState } from '@/store';
import {
  useAppStore,
  useChatStore,
  useMessengerStore,
  useNotificationsStore,
  useProjectsStore,
} from '@/store';
import type { MessageEntity, PushModel } from '@/types';

export type PushNotificationHandler = (data: PushModel) => Promise<void>;

export type PushNotificationLogType =
  | 'error'
  | 'none'
  | 'note'
  | 'message'
  | 'group'
  | 'user'
  | 'external-url'
  | 'wiki'
  | 'file';

export type IUseNotifications = {
  /**
   * @description Flag for push notifications initialization. If true, then push notifications are initialized and not need to reinitialize
   * @default false
   */
  isPushInitialized: boolean;
  /**
   * @description Initialize local notifications
   * @see https://capacitorjs.com/docs/apis/local-notifications
   */
  initLocalNotifications(): Promise<void>;
  /**
   * @description Schedule local notification to be called
   * @see https://capacitorjs.com/docs/apis/local-notifications#schedule
   * @todo Rewrite to be more abstract to take any type of notification and schedule it
   */
  scheduleLocalNotification(newMess: MessageEntity): Promise<void>;
  /**
   * @description Cancel local pending notifications
   * @see https://capacitorjs.com/docs/apis/local-notifications#cancel
   */
  cancelLocalNotifications: (chainId: number, count?: number) => Promise<void>;
  /**
   * @description Reset badge count to 0
   */
  resetBadgeCount(): Promise<void>;
  /**
   * @description Set unread notifications count
   * @see notifications from src/store/notification.pinia.ts
   */
  setBadgeCount(): Promise<void>;
  /**
   * @description Initialize push notifications
   * @see https://capacitorjs.com/docs/apis/push-notifications
   */
  initPushNotifications(): Promise<void>;
  /**
   * @description Cancel push notifications
   */
  cancelPushNotifications(): Promise<void>;
  /**
   * @description Handles the click event on the notification on NotificationsPage - /notifications
   * @see src/views/Notifications/NotificationsPage.vue
   */
  handleNotificationClick(
    id: number,
    isRead: boolean,
    type: string,
    entityId: number
  ): Promise<void>;
};

/* Singleton Instance of the useNotifications helper */
let instance: IUseNotifications | null = null;

/**
 * @description Helper for working with local and push notifications. Accepts a boolean parameter to reinitialize the instance if needed.
 */
export const useNotifications = (reinitialize = false): IUseNotifications => {
  /**
   * @description Singleton instance of the useNotifications helper
   * @returns IUseNotifications if instance exists, otherwise creates a new instance
   */
  if (instance && !reinitialize) {
    return instance;
  }

  //#region - Variables
  let isPushInitialized = false;
  //#endregion

  //#region - Stores
  const chatStore = useChatStore();
  const messengerStore = useMessengerStore();
  const notificationsStore = useNotificationsStore();
  const projectsStore = useProjectsStore();
  //#endregion

  //#region - Helpers
  const loadingHelper = useLoading();
  const taskManagementHelper = useTaskManagement();
  const isAndroid = isPlatform('android');
  const { t } = useI18n();
  //#endregion

  //#region - Private methods
  const _updateBadgeCount = async () => {
    /* NOTE: Getting all unread notifications from the server */
    await notificationsStore.unreadNotifications();
    const notificationsCount = notificationsStore.getUnreadNotificationsCount;

    /* NOTE: Getting all unread messages from the server */
    await messengerStore.updateUnreadCounter();
    const messagesCount = messengerStore.getUnreadMessagesCount;

    /* NOTE: Setting the badge count */
    const total = notificationsCount + messagesCount;
    const badgeCount = total > 99 ? 99 : total;
    await Badge.set({ count: badgeCount });
  };

  const _handleLocalNotification = async (chainId: number) => {
    console.log('≥≥≥Handling local notification: ', chainId); //! DEBUG

    if (chainId > 0) {
      await router?.push({
        name: ROUTES_NAME.MESSENGER_CHAT_BY_CHAIN,
        params: { id: chainId, type: MessengerChatTypeEnum.Chain },
      });
      messengerStore.resetUnreadMessagesCount(chainId);
      /* NOTE:
        Since we have reset the unread messages count in messengerStore,
        we just need to call _updateBadgeCount that is using messengerStore.getUnreadMessagesCount.
        So no need to increment the badge count manually
      */
      await _updateBadgeCount();
    }
  };

  const _getMessageBody = (newMess: MessageEntity): string => {
    const handleQuoteMessage = (message: MessageEntity) => {
      return message?.originalMessage ? t('messenger.chatPage.forwarded') : '';
    };

    const handleForwardMessage = () => {
      return t('messenger.chatPage.forwarded');
    };

    const handleDefaultMessage = (message: MessageEntity) => {
      const withFiles =
        message.attachedFiles.count > 0 ||
        (message?.forwardedAttachedFiles &&
          message.forwardedAttachedFiles.count > 0);
      return message.bodyHtml
        ? htmlToText(message.bodyHtml)
        : withFiles
          ? t('messenger.messengerSend.file')
          : '';
    };

    switch (newMess.messageType) {
      case MessageTypeEnum.Quote:
        return handleQuoteMessage(newMess);
      case MessageTypeEnum.Forward:
      case MessageTypeEnum.ForwardOld:
        return handleForwardMessage();
      default:
        return handleDefaultMessage(newMess);
    }
  };

  const _logAndShowToast = async (
    message: string,
    type: PushNotificationLogType
  ): Promise<void> => {
    console.log(`${type}: `, message);
    await showToast(
      message,
      false,
      false,
      undefined,
      `handle-push-notification-${type}`
    );
  };

  const _handleNonePushNotification = async (): Promise<void> => {
    console.warn(
      'Push notification type is None. No action needed. Check the server response.'
    );

    await _logAndShowToast(
      'Push notification type is None. No action needed. Check the server response.',
      'none'
    );
  };

  const _handleNotePushNotification = async (
    data: PushModel
  ): Promise<void> => {
    console.log('Note push notification: ', data); //! DEBUG

    await router?.push({
      name: ROUTES_NAME.POST_BY_ID,
      params: { id: data.id },
    });
  };

  const _handleMessagePushNotification = async (
    data: PushModel
  ): Promise<void> => {
    console.log('Message push notification: ', data); //! DEBUG

    const result = await chatStore.getChainById(Number(data.id));
    if (!result) {
      console.error('Failed to get chat by chain id: ', data);
      return;
    }

    await router?.push({
      name: ROUTES_NAME.MESSENGER_CHAT_BY_CHAIN,
      params: { id: data.id, type: MessengerChatTypeEnum.Chain },
    });
  };

  const _handleGroupPushNotification = async (
    data: PushModel
  ): Promise<void> => {
    console.log('Group push notification: ', data); //! DEBUG

    await router?.push({
      name: ROUTES_NAME.GROUP_BY_ID,
      params: { id: data.id },
    });
  };

  const _handleUserPushNotification = async (
    data: PushModel
  ): Promise<void> => {
    console.log('User push notification: ', data); //! DEBUG

    await router?.push({
      name: ROUTES_NAME.USER_BY_ID,
      params: { id: data.id },
    });
  };

  // TODO
  const _handleExternalUrlPushNotification = async (
    data: PushModel
  ): Promise<void> => {
    console.log('External URL push notification: ', data); //! DEBUG

    await _logAndShowToast('External URL push notification', 'external-url');
  };

  // TODO
  const _handleWikiPushNotification = async (
    data: PushModel
  ): Promise<void> => {
    console.log('Wiki push notification: ', data); //! DEBUG

    await _logAndShowToast('Wiki push notification', 'wiki');
  };

  // TODO
  const _handleFilePushNotification = async (
    data: PushModel
  ): Promise<void> => {
    console.log('File push notification: ', data); //! DEBUG

    await _logAndShowToast('File push notification', 'file');
  };

  const _handlePushNotificationRecord: Record<
    PushType,
    PushNotificationHandler
  > = {
    [PushType.None]: _handleNonePushNotification,
    [PushType.Note]: _handleNotePushNotification,
    [PushType.Message]: _handleMessagePushNotification,
    [PushType.AddedToGroup]: _handleGroupPushNotification,
    [PushType.InvitedToGroup]: _handleGroupPushNotification,
    [PushType.NewFollower]: _handleUserPushNotification,
    [PushType.ExternalUrl]: _handleExternalUrlPushNotification,
    [PushType.Wiki]: _handleWikiPushNotification,
    [PushType.File]: _handleFilePushNotification,
  };

  /*
    TODO: Later connect with useErrorHelper from to log errors with Sentry
   */
  const _handlePushNotification = async (
    notification: PushNotificationSchema
  ): Promise<void> => {
    console.log('≥≥≥Handling push notification: ', notification); //! DEBUG

    const data = notification.data as PushModel;

    // await SplashScreen.show(); //! TESTING #1610

    try {
      /* NOTE: Handling the push notification based on its type */
      const handleFunction = _handlePushNotificationRecord[data.t];
      if (handleFunction) {
        await handleFunction(data);
      } else {
        throw new Error(`Unknown push notification type: ${data.t}`);
      }

      /* NOTE: If the notification was handled successfully, mark it as read */
      await notificationsStore.markAs([Number(data.id)], true);
    } catch (error) {
      console.error('Error handling push notification: ', error, data);
      await _logAndShowToast('Error handling push notification.', 'error');
    } finally {
      // await SplashScreen.hide(); //! TESTING #1610
      /* NOTE: Updating the badge count */
      await _updateBadgeCount();
    }
  };

  const _handleClickPrivateMessageNotification = async (goToId: number) => {
    await router.push({
      name: ROUTES_NAME.MESSENGER_CHAT_BY_CHAIN,
      params: { id: goToId, type: MessengerChatTypeEnum.Chain },
    });
  };

  const _handleClickUserRelatedNotification = async (goToId: number) => {
    await router.push({
      name: ROUTES_NAME.USER_BY_ID,
      params: { id: goToId },
    });
  };

  const _handleClickPostRelatedNotification = async (goToId: number) => {
    await router.push({
      name: ROUTES_NAME.POST_BY_ID,
      params: { id: goToId },
    });
  };

  const _handleClickGroupNotification = async (goToId: number) => {
    await router.push({
      name: ROUTES_NAME.GROUP_BY_ID,
      params: { id: goToId },
    });
  };

  const _handleClickWikiNotification = async (goToId: number) => {
    await router.push({
      name: ROUTES_NAME.WIKI_BY_ID,
      params: { id: goToId },
    });
  };

  const _handleClickFileNotification = async (goToId: number) => {
    await router.push({
      name: ROUTES_NAME.FILE_BY_ID,
      params: { id: goToId },
    });
  };

  const _handleClickTaskNotification = async (goToId: number) => {
    await loadingHelper.create(useI18n().t('loading'));

    const isExists = await projectsStore.taskById(goToId);

    if (isExists) {
      const projectId = projectsStore.getProjectIdByTaskId(goToId);
      if (projectId) {
        if (!projectsStore.getProjectById(projectId)) {
          await projectsStore.projectById(projectId);
        }

        const location = {
          query: { showTaskId: goToId, projectId: projectId },
        };
        await router.push(location);
      }
    }

    await loadingHelper.dismiss();
  };

  const _handleClickTaskWithManagementAccess = async (goToId: number) => {
    if (!taskManagementHelper.getAccessToTaskManagement()) {
      console.warn('User does not have access to task management');
    }
    await _handleClickTaskNotification(goToId);
  };

  /**
   * @description Takes a handler function and an array of notification types to be paired
   * @example _createHandlersMap(_handleClickUserRelatedNotification, userRelatedNotifications) - returns
   * {
   *  [NotificationsTypeEnum.NewFollower]: _handleClickUserRelatedNotification,
   *  [NotificationsTypeEnum.NewManager]: _handleClickUserRelatedNotification,
   *  [NotificationsTypeEnum.NewSubordinate]: _handleClickUserRelatedNotification
   * }
   */
  const _createHandlersMap = (
    handler: (goToId: number) => Promise<void>,
    types: NotificationsPushActionsEnum[]
  ): Partial<
    Record<NotificationsPushActionsEnum, (goToId: number) => Promise<void>>
  > => {
    return Object.fromEntries(types.map((type) => [type, handler]));
  };

  // User related notification types
  const userRelatedNotifications = [
    NotificationsPushActionsEnum.User,
    NotificationsPushActionsEnum.UserCreate,
    NotificationsPushActionsEnum.UserNewFollower,
    NotificationsPushActionsEnum.UserNewManager,
    NotificationsPushActionsEnum.UserNewSubordinate,
    NotificationsPushActionsEnum.UserNewLevel,
  ];

  // Posts related notification types
  const postRelatedNotifications = [
    NotificationsPushActionsEnum.PostLiked,
    NotificationsPushActionsEnum.Post,
    NotificationsPushActionsEnum.PostCreate,
    NotificationsPushActionsEnum.PostLiked,
    NotificationsPushActionsEnum.PostCommentCreate,
    NotificationsPushActionsEnum.PostCommentAfterYouComment,
    NotificationsPushActionsEnum.PostCommentToYourUserItem,
    NotificationsPushActionsEnum.PostCommentMarkedAsHelpful,
    NotificationsPushActionsEnum.PostCommentCreateMentioned,
    NotificationsPushActionsEnum.PostCommentLiked,
    NotificationsPushActionsEnum.PostAnnouncement,
    NotificationsPushActionsEnum.PostPlannedCreated,
    NotificationsPushActionsEnum.PostPlannedFailed,
  ];

  const messageRelatedNotifications = [
    NotificationsPushActionsEnum.Message,
    NotificationsPushActionsEnum.MessageCreate,
  ];

  // Group related notification types
  const groupRelatedNotifications = [
    NotificationsPushActionsEnum.Group,
    NotificationsPushActionsEnum.GroupCreate,
    NotificationsPushActionsEnum.GroupAdded,
    NotificationsPushActionsEnum.GroupInvited,
    NotificationsPushActionsEnum.GroupRequestJoin,
    NotificationsPushActionsEnum.GroupRequestJoinDeny,
  ];

  // Wiki related notification types
  const wikiRelatedNotifications = [
    NotificationsPushActionsEnum.Wiki,
    NotificationsPushActionsEnum.WikiCreate,
    NotificationsPushActionsEnum.WikiNewVersion,
    NotificationsPushActionsEnum.WikiMentioned,
  ];

  const fileRelatedNotifications = [
    NotificationsPushActionsEnum.File,
    NotificationsPushActionsEnum.FileCreate,
    NotificationsPushActionsEnum.FileNewVersion,
  ];

  // Task management related notification types
  const taskManagementNotifications = [
    NotificationsPushActionsEnum.Task,
    NotificationsPushActionsEnum.TaskCreated,
    NotificationsPushActionsEnum.TaskClosed,
    NotificationsPushActionsEnum.TaskReopened,
    NotificationsPushActionsEnum.TaskArchived,
    NotificationsPushActionsEnum.TaskDeArchived,
    NotificationsPushActionsEnum.TaskTitleUpdated,
    NotificationsPushActionsEnum.TaskDescriptionUpdated,
    NotificationsPushActionsEnum.TaskAssigneeChanged,
    NotificationsPushActionsEnum.TaskMilestoneChanged,
    NotificationsPushActionsEnum.TaskDateStartUpdated,
    NotificationsPushActionsEnum.TaskDateDueUpdated,
    NotificationsPushActionsEnum.TaskTagsAdded,
    NotificationsPushActionsEnum.TaskTagsRemoved,
    NotificationsPushActionsEnum.TaskFilesAdded,
    NotificationsPushActionsEnum.TaskFilesRemoved,
    NotificationsPushActionsEnum.TaskWikisAdded,
    NotificationsPushActionsEnum.TaskWikisRemoved,
    NotificationsPushActionsEnum.TaskLinksAdded,
    NotificationsPushActionsEnum.TaskLinksRemoved,
    NotificationsPushActionsEnum.TaskParticipantsAdded,
    NotificationsPushActionsEnum.TaskParticipantsRemoved,
    NotificationsPushActionsEnum.TaskCommentCreated,
    NotificationsPushActionsEnum.TaskCommentUpdated,
    NotificationsPushActionsEnum.TaskCommentDeleted,
    NotificationsPushActionsEnum.TaskMoved,
    NotificationsPushActionsEnum.TaskReporterChanged,
  ];

  /**
   * @description Map of notification types and their click handlers
   * @see _createHandlersMap
   * @note Partial is used to prevent TypeScript error - compiler thinks that not all types are covered since se are using Partial in _createHandlersMap as well
   */
  const _notificationClickHandlers: Partial<
    Record<NotificationsPushActionsEnum, (goToId: number) => Promise<void>>
  > = {
    ..._createHandlersMap(
      _handleClickUserRelatedNotification,
      userRelatedNotifications
    ),
    ..._createHandlersMap(
      _handleClickPostRelatedNotification,
      postRelatedNotifications
    ),
    ..._createHandlersMap(
      _handleClickPrivateMessageNotification,
      messageRelatedNotifications
    ),
    ..._createHandlersMap(
      _handleClickGroupNotification,
      groupRelatedNotifications
    ),
    ..._createHandlersMap(
      _handleClickWikiNotification,
      wikiRelatedNotifications
    ),
    ..._createHandlersMap(
      _handleClickFileNotification,
      fileRelatedNotifications
    ),
    ..._createHandlersMap(
      _handleClickTaskWithManagementAccess,
      taskManagementNotifications
    ),
  };

  const _isBadgeSupported = async (): Promise<boolean> => {
    if (!isNativeMobile || !(await Badge.isSupported())) {
      console.warn('Badge is not supported on this platform');
      return false;
    }
    return true;
  };
  //#endregion

  //#region - Public methods
  const initLocalNotifications = async (): Promise<void> => {
    console.log('≥≥≥Initializing local notifications'); //! DEBUG

    if (isNativeMobile) {
      let permStatus = await LocalNotifications.checkPermissions();
      if (permStatus.display === 'prompt') {
        permStatus = await LocalNotifications.requestPermissions();
      }
      if (permStatus.display !== 'granted') {
        alert(t('deniedPermissions'));
      }

      await LocalNotifications.registerActionTypes({
        types: [
          {
            id: 'NEW_CHAT_MESSAGE',
            actions: [
              {
                id: 'view',
                title: t('open'),
              },
            ],
          },
        ],
      });

      await LocalNotifications.addListener(
        'localNotificationActionPerformed',
        (notification: any) => {
          console.log('≥≥≥Local notification action performed: ', notification); //! DEBUG

          /* NOTE: Setting the flag to indicate that the app was opened from a push notification */
          useAppStore().$patch((state: AppState) => {
            state.fromMobilePush = true;
          });

          _handleLocalNotification(notification.notification.id);
        }
      );
    }
  };

  const scheduleLocalNotification = async (
    newMess: MessageEntity
  ): Promise<void> => {
    console.log('≥≥≥Scheduling local notification: ', newMess); //! DEBUG

    const scheduleObj = {
      notifications: [
        {
          title: newMess.authorFullName,
          body: _getMessageBody(newMess),
          id: newMess.chainId,
          actionTypeId: 'NEW_CHAT_MESSAGE',
        },
      ],
    };
    /* NOTE:
      Since we are scheduling a new local notification when a _onPushMessage is called (connection.on('pushMessage', _onPushMessage))
      @see _registerConnectionEvents - src/helpers/useWebSocketsHelper.ts
      _onPushMessage inside calls messengerStore.updateLastMessage that updates messengerStore.data.unreadMessagesCount
      So we just need to call _updateBadgeCount that is using messengerStore.getUnreadMessagesCount,
      no need to increment the badge count manually
    */
    await _updateBadgeCount();
    await LocalNotifications.schedule(scheduleObj);
  };

  const cancelLocalNotifications = async (chainId: number): Promise<void> => {
    console.log('≥≥≥Cancelling local notifications: ', chainId); //! DEBUG

    await LocalNotifications.cancel({ notifications: [{ id: chainId }] });
    /* NOTE:
      Updating the badge count - unreadMessagesCount in messengerStore has already been updated in this chain
      @see src/components/Messenger/MessengerContent.vue
    */
    await _updateBadgeCount();
  };

  const resetBadgeCount = async (): Promise<void> => {
    console.log('≥≥≥Resetting badge count'); //! DEBUG

    if (!(await _isBadgeSupported())) return;

    await Badge.clear();
  };

  const setBadgeCount = async (): Promise<void> => {
    console.log('≥≥≥Setting badge count'); //! DEBUG

    if (!(await _isBadgeSupported())) return;

    /* NOTE: Resetting badge count to 0 */
    await resetBadgeCount();

    /* NOTE: Checking permissions for the badge */
    let permStatus = await Badge.checkPermissions();
    if (permStatus.display === 'prompt') {
      permStatus = await Badge.requestPermissions();
    }
    if (permStatus.display !== 'granted') {
      alert(t('deniedPermissions'));
      return;
    }

    try {
      /* NOTE: Setting the badge count */
      await _updateBadgeCount();
    } catch (error) {
      console.error('Failed to set unread notifications count: ', error);
      await _logAndShowToast(
        'Failed to set unread notifications count.',
        'error'
      );
    }
  };

  const initPushNotifications = async (): Promise<void> => {
    console.log('≥≥≥Initializing push notifications'); //! DEBUG

    if (!isNativeMobile) {
      console.warn('Push notifications are not supported on this platform');
      return;
    }

    /* NOTE: Called when the push notification registration finishes without problems */
    await PushNotifications.addListener('registration', async (token) => {
      console.log('≥≥≥Registration token: ', token.value); //! DEBUG

      /* NOTE: Setting init flag to true since registration has been successful */
      isPushInitialized = true;

      /* NOTE: Setting unread notifications count */
      await setBadgeCount();

      const deviceId = await Device.getId();
      const appInfo = await App.getInfo();

      await $api.account.register({
        pushToken: token.value,
        deviceId: deviceId.identifier,
        platform: isAndroid ? 'android' : 'ios',
        packageId: appInfo.name,
        allNetworks: true,
      });
    });

    /* NOTE: Called when the push notification registration finished with problems */
    await PushNotifications.addListener('registrationError', (err) => {
      console.error('Registration error: ', err.error);
    });

    /* NOTE: Called when the device receives a push notification */
    await PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        console.log('≥≥≥Push notification received: ', notification); //! DEBUG
        /* NOTE:
          Since _updateBadgeCount is using notificationsStore.getUnreadNotificationsCount,
          we just need to call _updateBadgeCount to update the badge count,
          no need to increment the badge count manually
          notificationsStore.getUnreadNotificationsCount + messengerStore.getUnreadMessagesCount SHOULD BE equal to the badge count ALWAYS
        */
        await _updateBadgeCount();
      }
    );

    /* NOTE: Called when an action is performed on a push notification */
    await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (action: ActionPerformed) => {
        console.log('≥≥≥Push notification action performed: ', action); //! DEBUG

        /* NOTE: Setting the flag to indicate that the app was opened from a push notification */
        useAppStore().$patch((state: AppState) => {
          state.fromMobilePush = true;
        });

        _handlePushNotification(action.notification);
      }
    );

    /* NOTE: Checking permissions for push notifications */
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }
    if (permStatus.receive !== 'granted') {
      alert(t('deniedPermissions'));
    }

    /* NOTE: Registering the device for push notifications */
    await PushNotifications.register();
  };

  const cancelPushNotifications = async (): Promise<void> => {
    console.log('≥≥≥Cancelling push notifications'); //! DEBUG

    if (isNativeMobile) {
      const deviceId = await Device.getId();
      const appInfo = await App.getInfo();
      await $api.account.unRegister({
        deviceId: deviceId.identifier,
        platform: isAndroid ? 'android' : 'ios',
        packageId: appInfo.name,
        allNetworks: true,
      });
    }
  };

  const handleNotificationClick = async (
    id: number,
    isRead: boolean,
    type: NotificationsPushActionsEnum,
    entityId: number
  ): Promise<void> => {
    const handler = _notificationClickHandlers[type];

    if (handler) {
      await handler(entityId);
    } else {
      console.warn(`No handler for notification type: ${type}`);
    }

    if (!isRead) {
      const result = await notificationsStore.markAs([id], true);
      if (!result) {
        await showToast(t('errorResponse'), false);
      }
    }
  };
  //#endregion

  instance = {
    isPushInitialized,
    resetBadgeCount,
    setBadgeCount,
    initLocalNotifications,
    scheduleLocalNotification,
    cancelLocalNotifications,
    initPushNotifications,
    cancelPushNotifications,
    handleNotificationClick,
  };

  return instance;
};
